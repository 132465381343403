import { SettingsIcon } from 'components/common/Icon';
import { useCurrentAccount, useNavigate } from 'hooks/api';
import { Routes } from 'fnRoutes';
import { isAccountFullSuite } from 'components/ProgressTracker/util';
import { useTranslation } from 'react-i18next';
import { NavMenuItem } from './NavMenuItem';

export const PreferencesMenu = () => {
  const { t } = useTranslation();
  const { currentAccount } = useCurrentAccount();
  const navigate = useNavigate();
  const isFullSuite = isAccountFullSuite(currentAccount);
  const customerStatus = currentAccount?.status?.label ?? '';

  const goToAccountSetting = () => {
    navigate(Routes.ACCOUNT_SETTING);
  };

  const goToMySubscription = () => {
    navigate(Routes.MY_SUBSCRIPTION);
  };

  const goToMySetup = () => {
    navigate(Routes.SETUP_PAGE);
  };

  const menuList = [
    {
      name: t('topNavBar.preferences.accountSettings'),
      disabledForStatus: [],
      clickHandler: goToAccountSetting,
      disabled: true,
      hidden: true,
    },
    {
      name: t('topNavBar.preferences.mySetup'),
      disabledForStatus: ['NEW'],
      clickHandler: goToMySetup,
      hidden: !isFullSuite,
    },
    {
      name: t('topNavBar.preferences.mySubscription'),
      disabledForStatus: ['NEW'],
      clickHandler: goToMySubscription,
    },
    {
      name: t('topNavBar.preferences.connections'),
      disabled: true,
      hidden: true,
    },
  ];

  return (
    <NavMenuItem
      tooltipTitle={t('topNavBar.preferences.tooltipTitle')}
      menuList={menuList}
      mainIcon={SettingsIcon}
      menuName={t('topNavBar.preferences.name')}
      customerStatus={customerStatus}
      account={currentAccount}
    />
  );
};
